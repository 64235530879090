import {Layout} from '../../components/layout';
import * as styles from './index.module.css';

const AnalyticsWEPage = () => (
    <Layout title="Analytics (APAC)" scope="analytics.apac">
        <div className={styles.wrapper}>
            <iframe
                title="APAC YT Quarterly Performace Dashboard_v4"
                width="100%"
                height="100%"
                src="https://app.powerbi.com/reportEmbed?reportId=f0af80d0-b153-45fc-b54a-b3ca135d45c0&autoAuth=true&ctid=ae087f2d-8a82-46dd-a538-38bebd294479"
                className={styles.embed}
            >
            </iframe>
        </div>
    </Layout>
);

export default AnalyticsWEPage;
